<div
    [ngClass]="{'-expanded': isExpanded}"
    (click)="onClick()"
    (wheel)="onScroll($event)"
    #project
>
    <div class="-container">
        <div class="-icons">
			<div class="icon -expand"></div>
			<div class="icon -collapse" (click)="onCollapseClick($event)"></div>
		</div>
        <div class="-image">
            <img src="{{image}}" alt="{{title}}">
        </div>
        <div class="-text">
            <h3 class="-title">
                {{title}}
            </h3>
            <div *ngIf="isExpanded">
                <ng-container  *ngIf="done.length">
                    <h4>Work Done</h4>
                    <ul>
                        <li *ngFor="let d of done">{{d}}</li>
                    </ul>
                </ng-container>
                <ng-container  *ngIf="tech.length">
                    <h4>Technologies and tools</h4>
                    <ul>
                        <li *ngFor="let tech of tech">{{tech}}</li>
                    </ul>
                </ng-container>
                
            </div>
        </div>
    </div>
</div>
