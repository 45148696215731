<div class="-filters" (click)="onFilterSelect($event)">
    <a href="#all" data-filter="all">All</a>
    <a href="#design" data-filter="design">Design</a>
    <a href="#htmlcss" data-filter="htmlcss">HTML/CSS</a>
    <a href="#wordpress" data-filter="wordpress">WordPress</a>
    <a href="#ecommerce" data-filter="ecommerce">eCommerce</a>
    <a href="#joomla" data-filter="joomla">Joomla</a>
</div>
 
<div class="-works">
    <app-project
        *ngFor="let project of projects"
        [title]="project.title"
        [image]="project.thumbnail"
        [thumbnail]="project.thumbnail"
        [imageBig]="project.image"
        [done]="project.done"
        [tech]="project.tech"
        [tags]="project.tags"
        (onExpanded)="onExpanded()"
    >
    </app-project>
    
</div>
<button class="-nav -prev" (click)="prevPage($event)" [hidden]="pages[0]"><span>Prev Page</span></button>
<button class="-nav -next" (click)="nextPage($event)" [hidden]="pages[pages.length-1]"><span>Next Page</span></button>
<ul class="-pagination">
    <li *ngFor="let p of pages; let i = index">
       <button (click)="toPage(i)" [ngClass]="{'-current': p[i]}"><span>{{ i + 1 }}</span></button>
    </li>
</ul>
