import { AfterViewInit, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit, AfterViewInit {
  isAccordionActive: boolean = false;
  @ViewChild('svgWp') svgWp!: ElementRef;
  @ViewChildren('svgLogo', {read: ElementRef}) svgLogos!: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2
    ) {
    
  }

   
  // React if accordion is changing its state
  onAccordionActive(event: boolean): void {
    this.isAccordionActive = event;
  }

  translateElement(el: ElementRef, x: number, y: number): void {
    this.renderer.setStyle(el, 'transform', `translate(${x}px, ${y}px)`);
  }

  onMouseMove(event: MouseEvent): void {
    this.translateElement(this.svgWp.nativeElement, event.x/50, event.y/50);
  }
  

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
    for (let logo of this.svgLogos) {
      setInterval(() => {
        this.translateElement(
          logo.nativeElement,
          Math.floor(Math.random()*10),
          Math.floor(Math.random()*10)
        );
      }, 2000 + Math.random()*1000);
    
    }
  }

}
