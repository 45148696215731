<section id="section-contacts" #sectionContacts>
    <div class="inner">
        <h2>Contacts</h2>
        <div class="-text">Please feel free to write me a message</div>
        <div class="-form">
            <form id="contact-from" method="POST" [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="{'-sending': formSending, '-sent': formSent}">
                <div class="-form-field -name">
                    <input id="cf-fname" type="text" placeholder="Name" formControlName="name">
                    <div *ngIf="form.get('name')!.touched && form.get('name')!.status === 'INVALID'" class="-error">
                        <span>I would like to know your name.</span>
                    </div>
                </div>
                <div class="-form-field -email">
                    <input id="cf-email" type="email" placeholder="Email" formControlName="email">
                    <div *ngIf="form.get('email')!.touched && form.get('email')!.status === 'INVALID'" class="-error">
                        <span *ngIf="form.get('email')!.errors!.required">I need your email to contact you.</span>
                        <span *ngIf="form.get('email')!.errors!.email">Looks like the email is incorrect.</span>
                    </div>
                </div>
                <div class="-form-field -message">
                    <textarea id="cf-message" placeholder="Message" formControlName="message"></textarea>
                    <div *ngIf="form.get('message')!.touched && form.get('message')!.status === 'INVALID'" class="-error">
                        <span>Please, write a few words.</span>
                    </div>
                </div>
                <input type="hidden" name="recaptcha_response" id="recaptchaResponse">
                
                <button class="button -icon -send" type="submit" [disabled]="form.status === 'INVALID' && form.touched"><span>Send</span></button>

                <div id="form-result">Thank you! I shall contact you shortly</div>
            </form>
        </div>
    </div>

</section>