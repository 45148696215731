<section id="section-wws" #section>
			<div class="inner">
				<div class="-tabs-nav">
					<div class="-col -what">
						<h3><a href="#what" #tabLink>What I do</a></h3>
					</div>
					<div class="-col -why">
						<h3><a href="#why" #tabLink>Why me</a></h3>
					</div>
					<div class="-col -statistics">
						<h3><a href="#statistics" #tabLink>Statistics</a></h3>
					</div>
				</div>
				<div class="-tabs-container" #tabsContainer>
					<div class="-col -what -current" #tabContainer>
						<ul>
							<li class="-i1">
								<h2>Designing</h2>
								<p>I am capable to develop a site from the scratch.</p>
							</li>
							<li class="-i2">
								<h2>Development</h2>
								<p>I use WordPress as a main platform, but I do not forget about others. Some time I use: joomla, opencart. I used to use drupal, but very long ago.</p>
							</li>
							<li class="-i3">
								<h2>Maintenance</h2>
								<p>Websites need to keep them healthy and I know how to do this.</p>
							</li>
						</ul>
					</div>
					<div class="-col -why" #tabContainer>
						<ul>
							<li class="-i1">
								<h2>Always online</h2>
								<p>I am kidding sometimes I sleep but I am always in touch.</p>
							</li>
							<li class="-i2">
								<h2>Keeping deadlines</h2>
								<p>I am very serious about meeting them.</p>
							</li>
							<li class="-i3">
								<h2>Full-stack web developer</h2>
								<p>You do need only me to develop a great website.</p>
							</li>
						</ul>
					</div>
					<div class="-col -statistics" #tabContainer>
						<div class="-graph">
							<img src="assets/images/statistics.svg" alt="">
						</div>
						<div class="-legend">
							<h4>Total of projects: 289</h4>
							<ul>
								<li>Designs: <span>66</span></li>
								<li>HTML/CSS markups: <span>281</span></li>
								<li>WordPress: <span>103</span></li>
								<li>Joomla: <span>28</span></li>
								<li>Other CMS: <span>35</span></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>