import { Component, ViewEncapsulation, OnInit, Output, EventEmitter  } from '@angular/core';

import { AccordionContent } from './accordion-content.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements OnInit {
  accordionContent: AccordionContent[] = [
    new AccordionContent('Skills', `<ul>
      <li>Front-end web development (HTML5, CSS3, SCSS, Javascript, jQuery, Node, Gulp);</li>
      <li>Back-end web development (PHP, MySQL);</li>
      <li>Good knowledge of version control systems (Git)</li>
      <li>WordPress theme development;</li>
      <li>Joomla! template development;</li>
      <li>Ability to keep web-standards;</li>
      <li>Website design, implementation and maintenance;</li>
      <li>Implementation and testing of responsive designs;</li>
      <li>Working in Photoshop and Illustrator;</li>
    </ul>`),
    new AccordionContent('Education', `<h5>Education</h5>
      <h6>2000 - 2005</h6>
      <p>Chernivitskiy National University, Faculty of Physics<br>Obtained a master's degree with distinction in solid state physics, with specialization in information technology in physics.</p>
      <h6>2005 - 2008</h6>
      <p>Graduate student at Institute of Metal Physics of NASU, Kiev, Ukraine<br>Obtained the degree of Ph.D. in Physics and Mathematics.</p>
      <h5>Additional education</h5>
      <h6>03.2008 - 09.2008</h6>
      <p>Business English courses;</p>
      <h6>09.2009 - 12.2010</h6>
      <p>Individual English lessons.</p>`),
    new AccordionContent('Work experience', `<!--<h6>08.2006 - pres. time </h6>
      <h5>Freelance Web-designer/developer </h5>
      <p>Designing websites; html/css markuping; creating WordPress and Joomla themes; full cycle website developing;</p>-->
      <h6>03.2010 - pres. time</h6> 
      <h5>Web-developer (LightMix Design Studio) </h5>
      <p>Developing websites; xhtml/css markuping; creating themes for WordPress and Joomla;  </p>
      <h6>08.2007 - 10.2008</h6>
      <h5>Web-developer, flash-developer, html-coder (DDM Studio), </h5>
      <p>Web-page markup, flash-banners development, email markup, supporting web-sites.</p>
      <h6>06.2006 - 03.2010</h6>
      <h5>Web designer, front-end developer (DreamArts Web-Studio), </h5>
      <p>Website design, html/css markup, flash site development.</p>`)
  ];
  @Output() onActive = new EventEmitter<boolean>();
  isActive: boolean = false;
  openCount: number = 0;

  constructor() { }

  onClick(event: MouseEvent, el: any): void {
    event.preventDefault();
    //el.visible = el.visible ? false : true;
    if ( el.visible ) {
      el.visible = false;
      this.openCount--;
    } else {
      el.visible = true;
      this.openCount++;
    }
    if ( this.openCount > 0 ) {
      this.onActive.emit(true);
    } else {
      this.onActive.emit(false);
    }
    this.onActive.subscribe(
      value => {
        this.isActive = value;
      }
    );
  }

  onWheel(event: MouseEvent) {
    if ( this.isActive ) {
      event.stopPropagation();
    }
  }

  ngOnInit(): void {
  }

}
