import { Component, HostListener, ElementRef, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isMobileMenuVisible: boolean = false;
  @Output() navigateToSection = new EventEmitter();
  isScrolled: boolean = false;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event:any){
    this.isScrolled = window.pageYOffset > 100  ? true : false
  }

  constructor() { }

  onClickLink(event: MouseEvent, el: string):void {
    event.preventDefault();
    this.navigateToSection.emit(el);
    this.onMobileMenuClick();
  }

  onMobileMenuClick(event?: MouseEvent) {
    this.isMobileMenuVisible = !this.isMobileMenuVisible;
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
   
  }
 
}
