import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
//import { Observable, Subject } from 'rxjs';
//import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'ngRomul';
  activeSection: string = '';
  @ViewChild('about') about!: ElementRef;
  @ViewChild('services') services!: ElementRef;
  @ViewChild('portfolio') portfolio!: ElementRef;
  @ViewChild('contacts') contacts!: ElementRef;

  sections: Array<ElementRef> = [];
  currentSection: number = 0;
  //currentSection$: Subject<number> = new Subject<number>();

 // scrollAvailable: boolean = true;
  scrollTimestamp: number = 0;

  /*
  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    //console.log(event)
  }
  */
  @HostListener('wheel', ['$event', 'bubbles: false '])
  onWheelScroll(event: WheelEvent) {
    event.preventDefault();
    if ( event.timeStamp - this.scrollTimestamp > 500 ) { // This helps to prevent from scrolling multiple sections at once
      //console.log(event.deltaY);
      //console.log(event.timeStamp - this.scrollTimestamp);
      if (event.deltaY > 0) {
        this.currentSection = this.currentSection === 3 ? 3 : this.currentSection + 1;
        this.scrollToSection(this.sections[this.currentSection]);
      } else {
        this.currentSection = this.currentSection === 0 ? 0 : this.currentSection - 1;
        this.scrollToSection(this.sections[this.currentSection]);
      }
      this.scrollTimestamp = event.timeStamp;
    }
  }
  
  constructor( ) {
    /*
    this.currentSection$.subscribe(
      value=>{
        console.log(value)
      }
    );
    this.currentSection$.next(0);
    this.currentSection$.next(1);
    */
  }

  navigateToSection(event: string): void {
    this.activeSection = 'active-section-' + event;
    switch (event) {
      case 'about':
        this.currentSection = 0;
        this.scrollToSection(this.sections[this.currentSection]);
          
        break;
      case 'services':
        this.currentSection = 1;
        this.sections[this.currentSection].nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        
        break;
      case 'portfolio':
        this.currentSection = 2;
        this.sections[this.currentSection].nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        
        break;
      case 'contacts':
        this.currentSection = 3;
        this.sections[this.currentSection].nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        
        break;
    }
    

  }
  scrollToSection(section: ElementRef): void {
    section.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
  ngAfterViewInit(): void {
    this.sections = [
      this.about,
      this.services,
      this.portfolio,
      this.contacts,      
    ]    
    
  }
}
