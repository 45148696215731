import { animate, transition, trigger, state, style } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Injectable, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animOpacity', [
      state('default', style({opacity: '0'})),
      state('visible', style({opacity: '1'})),
      transition('default => visible', [animate('0.2s')])
    ])
  ]
})

export class ContactsComponent implements OnInit {
  form!: FormGroup;
  @ViewChild('sectionContacts') sectionContacts!: ElementRef;
  formSending: boolean = false;
  formSent: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required, Validators.minLength(20), Validators.maxLength(2000)])
    });

  }
  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.formSending = true;
      this.http.post('/send_form.php', this.form.value, {headers: new HttpHeaders({'Content-Type': 'application-json'}), responseType: 'text'})
        .subscribe(response => {
          
          this.formSending = false;
          this.formSent = true;
          //Reset the form
          setTimeout(()=>{
            this.formSent = false;
            this.form.reset();
          }, 2000)
        });

    }
  }

}
