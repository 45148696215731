import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <a [href]="href" [class]="'button ' + classes" (click)="goToSection($event)">
      <span>
        <ng-content></ng-content>
      </span>
    </a>
  `,
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
  
})
export class ButtonComponent implements OnInit {
  @Input() scrollTo: string;
  @Input() href: string;
  @Input() classes: string;
  
  constructor() {
    this.scrollTo = '';
    this.href = '';
    this.classes = '';
  }

  goToSection(event: MouseEvent):void {
    const el = event.currentTarget as HTMLAnchorElement;

   // if (el.classList.contains('-nav')) {
    if (this.href.substring(0,1) === '#') {
      event.preventDefault();
      //console.log(this.href.substring(1));
      document.getElementById(this.href.substring(1))!.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }

  ngOnInit(): void {
  }

}
