import { Component, Input, OnChanges, OnInit, SimpleChange, EventEmitter } from '@angular/core';
import { Project } from './project.model';
import { Projects } from './projects.service'

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects!: Array<Project>;
  start: number = 0;
  filter: string = '';
  perPage: number = 6;
  pages: Array<string> = [];
  currentPage: number = 0;
  firstPage: boolean = true;
  lastPage: boolean = false;
  
  
  constructor(private projectsService: Projects) {
   
  }
  
  numberOfProjects(filter: string = ''): number {
    return this.projectsService.count(filter);
  }
  loadProjects(shift: number, filter?: string): void {
    this.start = this.start + shift;
    let currentNumberOfProjects = this.numberOfProjects(this.filter);
   
   
    this.projects = this.projectsService.get(this.start, this.start + this.perPage, this.filter);
    this.pages.length = Math.ceil(currentNumberOfProjects/this.perPage);
    this.pages.fill('');
    this.pages[this.start/this.perPage] = '-current';
  }
  
  prevPage(event: MouseEvent): void {
    this.loadProjects(-6);
  }
  nextPage(event: MouseEvent): void {
    this.loadProjects(6);
  }
  toPage(start: number): void {
    this.start = start * this.perPage;
    this.loadProjects(0);
  }
  onFilterSelect(event: MouseEvent): void {
    if (event.target === event.currentTarget) {
      return;
    }
    event.preventDefault();
    this.start = 0;
    this.filter = (<HTMLAnchorElement>event.target).dataset.filter || '';
    if (this.filter === 'all') this.filter = '';
    this.loadProjects(0, this.filter);
  }

  ngOnInit(): void {
    this.loadProjects(0);

  }
  onExpanded(): void {

  }


}
