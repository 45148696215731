export class AccordionContent {
    public title: string;
    public content: string;
    public visible: boolean;

    constructor (title: string, content: string) {
        this.title = title;
        this.content = content;
        this.visible = false;
    }  
}