import { Project } from './project.model';

export class Projects {
    private arrayOfWorks = [
        {
            title: 'WordPress site for a building company',
            image: '/assets/images/portfolio/fsbdroofing-wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/fsbdroofing-wordpress.jpg',
            description: '<p></p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WebSite redesign (Joomla to WordPress)',
            image: '/assets/images/portfolio/etno-selo-wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/etno-selo-wordpress.jpg',
            description: '<p></p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WebSite on HubSpot CMS',
            image: '/assets/images/portfolio/paper-hubspot.jpg',
            thumbnail: '/assets/images/portfolio/resized/paper-hubspot.jpg',
            description: '<p></p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Template for HubSpot CMS'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'HubSpot' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/epeat_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/epeat_html_css.jpg',
            description: '<p></p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'React' ],
            tags: [ 'htmlcss' ]
    
        },
        {
            title: 'WordPress site for InHouse',
            image: '/assets/images/portfolio/inhouse_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/inhouse_wordpress.jpg',
            description: '<p>I have developed the WordPress site from provided PSD mockups.</p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WooCommerce integration'
            ],
            tech: [ 'HTML5/CSS3', 'JavaScript (jQuery, Ajax, JSON)', 'PHP', 'WordPress', 'WooCommerce', 'openstreetmap.org API' ],
            tags: [ 'htmlcss', 'wordpress', 'ecommerce' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/cesgovernment_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/cesgovernment_wordpress.jpg',
            description: '<p>The site is built with the Divi theme</p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML5/CSS3', 'JavaScript', 'PHP', 'WordPress', 'Divi' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'User Interface',
            image: '/assets/images/portfolio/ahp_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/ahp_html_css.jpg',
            description: '<p>html/css markup from the psd templates.</p>',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Multilingual WordPress website',
            image: '/assets/images/portfolio/sjoaraftingsenter_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/sjoaraftingsenter_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WPML integration'
            ],
            tech: [ 'HTML', 'CSS', 'PHP', 'JavaScript', 'WordPress', 'WPML' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'Multilingual Wordpress Blog',
            image: '/assets/images/portfolio/blog_norwegianreward_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/blog_norwegianreward_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WPML integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress', 'WPML' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'Wordpress website',
            image: '/assets/images/portfolio/sweetstylish_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/sweetstylish_wordpress.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'design', 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/mindseeker_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/mindseeker_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive',
                'Divi integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/beaspermdonor_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/beaspermdonor_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/amercanex_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/amercanex_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'html/css markups',
            image: '/assets/images/portfolio/avastar_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/avastar_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/hram.in.ua_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/hram.in.ua_joomla.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Joomla templates'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla' ],
            tags: [ 'design', 'htmlcss', 'joomla' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/greenbud_kiev_ua_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/greenbud_kiev_ua_joomla.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Joomla template'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla', 'VirtueMart' ],
            tags: [ 'design', 'htmlcss', 'joomla', 'ecommerce' ]
        },
        {
            title: 'Simple HTML website with no CMS',
            image: '/assets/images/portfolio/intelligress_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/intelligress_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'html/css templates for UI',
            image: '/assets/images/portfolio/intelsat_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/intelsat_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Multilingual WordPress website',
            image: '/assets/images/portfolio/kingoskar_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/kingoskar_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WPML integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress', 'WPML' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'Simple HTML website',
            image: '/assets/images/portfolio/osmose_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/osmose_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/pfp_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/pfp_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Customized template for OpenCart',
            image: '/assets/images/portfolio/awc_opencart.jpg',
            thumbnail: '/assets/images/portfolio/resized/awc_opencart.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Opencart Integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'OpenCart' ],
            tags: [ 'htmlcss', 'ecommerce' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/softeon_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/softeon_joomla.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Joomla Templates'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla' ],
            tags: [ 'htmlcss', 'joomla' ]
        },
        {
            title: 'HTML templates',
            image: '/assets/images/portfolio/starmeet_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/starmeet_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Wordpress website',
            image: '/assets/images/portfolio/tropiky.in.ua_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/tropiky.in.ua_wordpress.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WooCommerce integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress', 'WooCommerce' ],
            tags: [ 'design', 'htmlcss', 'wordpress', 'ecommerce' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/uorcy_com_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/uorcy_com_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/active-elite_design.jpg',
            thumbnail: '/assets/images/portfolio/resized/active-elite_design.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme',
                'WooCommerce integration'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress', 'WooCommerce' ],
            tags: [ 'design', 'htmlcss', 'wordpress', 'ecommerce' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/sad-nadii_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/sad-nadii_wordpress.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/convergent-411_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/convergent-411_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/convergent-lts_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/convergent-lts_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'			
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/convergentmobile_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/convergentmobile_joomla.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Joomla template'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla' ],
            tags: [ 'htmlcss', 'joomla' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/edgecgroup_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/edgecgroup_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/etno-selo_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/etno-selo_joomla.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Joomla template',
                'Flash interactive map'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla' ],
            tags: [ 'design', 'htmlcss', 'joomla' ]
        },
        {
            title: 'Joomla and WordPress website',
            image: '/assets/images/portfolio/fairfax_joomla_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/fairfax_joomla_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'oomla template and WordPress theme (after a few years)'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress', 'Joomla' ],
            tags: [ 'htmlcss', 'wordpress', 'joomla' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/infillings_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/infillings_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'HTML/CSS templates',
            image: '/assets/images/portfolio/insiderscore_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/insiderscore_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/potomacriver_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/potomacriver_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/talkuments_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/talkuments_wordpress.jpg',
            description: '',
            done: [
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress Website',
            image: '/assets/images/portfolio/pizzadrive_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/pizzadrive_wordpress.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'design', 'htmlcss', 'wordpress' ]
        },
        {
            title: 'WordPress website',
            image: '/assets/images/portfolio/attorneyduilosangeles_wordpress.jpg',
            thumbnail: '/assets/images/portfolio/resized/attorneyduilosangeles_wordpress.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'WordPress theme'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'WordPress' ],
            tags: [ 'design', 'htmlcss', 'wordpress' ]
        },
        {
            title: 'HTML website',
            image: '/assets/images/portfolio/audio-resonance_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/audio-resonance_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript' ],
            tags: [ 'design', 'htmlcss' ]
        },
        {
            title: 'HTML website',
            image: '/assets/images/portfolio/postmarka_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/postmarka_html_css.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript (jQuery)' ],
            tags: [ 'design', 'htmlcss' ]
        },
        {
            title: 'HTML Landing Page',
            image: '/assets/images/portfolio/tsarsky_html_css.jpg',
            thumbnail: '/assets/images/portfolio/resized/tsarsky_html_css.jpg',
            description: '',
            done: [
                'HTML/CSS Markup'
            ],
            tech: [ 'HTML', 'CSS' ],
            tags: [ 'htmlcss' ]
        },
        {
            title: 'Joomla website',
            image: '/assets/images/portfolio/yoruma_joomla.jpg',
            thumbnail: '/assets/images/portfolio/resized/yoruma_joomla.jpg',
            description: '',
            done: [
                'Design',
                'HTML/CSS Markup',
                'Responsive Web Design',
                'Joomla template'
            ],
            tech: [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'Joomla' ],
            tags: [ 'design', 'htmlcss', 'joomla' ]
        }
        
    ]
    public count(filter: string = ''): number {
        if (filter !='') {
            return this.arrayOfWorks.filter(el=>{
                return el.tags.includes(filter)
            }).length;
        } else {
            return this.arrayOfWorks.length;
        }
    }
    public get(
            start: number = 0,
            end: number = start + 6,
            filter?: string
        ): Array<Project> {
        if (filter) {
            //console.log(this.arrayOfWorks);
            return this
                .arrayOfWorks
                .filter(el=>{
                    return el.tags.includes(filter)
                })
                .slice(start, end > this.count() ? this.count() : end );
        }
        return this.arrayOfWorks.slice(start, end > this.count() ? this.count() : end );
    }
}