import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @Input() title: string;
  @Input() thumbnail: string;
  @Input() imageBig: string;
  @Input() image: string;
  @Input() done: Array<string>;
  @Input() tech: Array<string>;
  @Input() tags: Array<string>;
  @Output() onExpanded = new EventEmitter();
  
  @ViewChild('project') project!: ElementRef;
  isExpanded: boolean;
  onExpandEvent = new CustomEvent( 'portfolioExpanded', { bubbles: true });

  constructor() {
    this.title = '';
    this.thumbnail = '';
    this.image = '';
    this.imageBig = '';
    this.isExpanded = false;
    this.done = [];
    this.tech = [];
    this.tags = [];
  }
  onClick(): void {
    this.isExpanded = true;
    this.onExpanded.emit(this.isExpanded);
    
    console.log(this.onExpandEvent);
    this.project.nativeElement.dispatchEvent(this.onExpandEvent);
    this.image = this.imageBig;
  }
  onCollapseClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isExpanded = false;
    this.onExpanded.emit(this.isExpanded);
    this.project.nativeElement.dispatchEvent(this.onExpandEvent);
    this.image = this.thumbnail;
  }
  onScroll(event: WheelEvent): void {
    if ((<HTMLDivElement>event.currentTarget).classList.contains('-expanded')) {
      event.stopPropagation();
    }
  }

  ngOnInit(): void {    
    
  }

}
