<div id="page" class="" >

	<app-header (navigateToSection)="navigateToSection($event)"></app-header>

	<main id="body" [ngClass]="activeSection">

		<div #about>
			<app-about></app-about>
		</div>
		
		<div #services>
			<app-wws></app-wws>
		</div>

		<div #portfolio>
			<app-portfolio></app-portfolio>
		</div>

		<div #contacts>
			<app-contacts></app-contacts>
		</div>
		
	</main>
	<app-footer></app-footer>
</div>