<header id="header" [ngClass]="{'-scrolled': isScrolled}">
    <div class="inner">
        <a href="#" id="header-logo" (click)="onClickLink($event, 'about')"><strong>Roman's</strong><span>website</span></a>
        <nav id="menu" [ngClass]="{'-visible': isMobileMenuVisible}" >
            <ul>
                <li><a href="#about" class="-nav" (click)="onClickLink($event, 'about')">About Me</a></li>
                <li><a href="#wws" class="-nav" (click)="onClickLink($event, 'services')">Services</a></li>
                <li><a href="#portfolio" class="-nav" (click)="onClickLink($event, 'portfolio')">Portfolio</a></li>
                <li><a href="#contacts" class="-nav" (click)="onClickLink($event, 'contacts')">Contacts</a></li>
            </ul>
            <div class="-close"></div>
        </nav>
        <button id="menu-mobile-icon" [ngClass]="{'-active': isMobileMenuVisible}" (click)="onMobileMenuClick($event)"></button>
        
    </div>
</header>