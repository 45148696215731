import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';

@Component({
  selector: 'app-wws',
  templateUrl: './wws.component.html',
  styleUrls: ['./wws.component.scss']
})
export class WwsComponent implements OnInit, AfterViewInit {
  @ViewChild('section') section!: ElementRef;
  //@ViewChild('tabsContainer') tabsContainer!: ElementRef;
  
  @ViewChildren('tabContainer', {read: ElementRef}) tabContainers!: QueryList<ElementRef>;
  @ViewChildren('tabLink', {read: ElementRef}) tabLinks!: QueryList<ElementRef>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setTabsClass();
  }
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setTabsClass();
    this.activateTabs();
  }

  setTabsClass():void {
    if (window.innerWidth < 768) {
      this.section.nativeElement.classList.add('-tabs-active');
    } else {
      this.section.nativeElement.classList.remove('-tabs-active');
    }
  }

  setActiveTab(currentTab: string): void {
    for (let tab of this.tabContainers) {
      tab.nativeElement.classList.remove('-current');
      if ( tab.nativeElement.classList.contains(currentTab) ) {
        tab.nativeElement.classList.add('-current');
      }
    }
  }

  activateTabs(): void {
    for (let link of this.tabLinks) {
      link.nativeElement.addEventListener('click', ($event: MouseEvent) => {
        $event.preventDefault();
        let currentElement = (<HTMLAnchorElement>$event.currentTarget);
        currentElement.parentElement?.parentElement?.parentElement?.querySelector('.-current')?.classList.remove('-current');
        currentElement.classList.add('-current');
        this.setActiveTab(currentElement.hash.replace('#', '-'));
      })
    }
  }

}
