<ul class="-accordion" (wheel)="onWheel($event)" [ngClass]="{'-active': isActive}">
    <li
        *ngFor="let ac of accordionContent"
        [ngClass]="{'-active': ac.visible}"
    >
        <a href="#" 
            (click)="onClick($event, ac)" 
            [textContent]="ac.title"
        ></a>
        <div 
            class="-accordion-popup" 
            [innerHTML]="ac.content"
        ></div>
    </li>
</ul>