import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioComponent implements OnInit {
  isExpanded: boolean = false;
  constructor() {

    
  }

  
  

  ngOnInit(): void {
    document.addEventListener('portfolioExpanded', ()=>{
      this.isExpanded = !this.isExpanded;
    });
  }

}
